import { Layout, Menu } from 'antd';
import { GlobalMetadataContext } from 'page/contexts/GlobalMetadataContext';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BasicRoute from 'router/router';
import { isMobile } from 'utils/util';
import LayoutStyle from './style';

const { Header, Content, Sider } = Layout


const Layouts = () => {
  const { current, setCurrent } = useContext(GlobalMetadataContext)
  const [collapsed, setCollapsed] = useState(false)
  const { pathname } = useLocation()
  const pattern = '/timeLine/';
  const match = pathname.includes(pattern);
  const showMenu = !match;

  const handleClick = (e) => {
    setCurrent(e.key)
  }

  useEffect(() => {
    const isMobileData = isMobile()
    if (isMobileData) {
      setCollapsed(true)
    }
  }, [])

  const items: MenuProps['items'] = [
    {
      label: <Link to="/">新增</Link>,
      key: '#/',
    },
    {
      label: <Link to="/search">查询预约</Link>,
      key: '#/search',
    },
    {
      label: <Link to="/timeLine">空闲时间表</Link>,
      key: '#/timeLine',
      hidden: true
    },
  ];

  return (
    <LayoutStyle>
      <Layout>
        {showMenu && (
          <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" items={items} />
        )}
        <Layout className="site-layout">
          <Content>
            <div className="site-layout-background" style={{ padding: 24, minHeight: (document.body.clientHeight - 88) }}>
              <BasicRoute></BasicRoute>
            </div>
          </Content>
        </Layout>
      </Layout>
    </LayoutStyle>
  )
}

export default Layouts