import Style from 'styled-components'

const LayoutStyle = Style.div`

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}
@media screen and (max-width: 450px) {
  .title {
    font-weight:500;
  }
 
}
@media screen and (min-width: 450px){
  .title{
    font-size:22px;
    font-weight:500
  }
}
.menu {
  font-size:16px;
}
 .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

 .trigger:hover {
  color: #1890ff;
}
.logo-wrap {
  height:64px;
  line-height:64px;
  overflow:hidden;
  text-align:center;
}
.logo{
  width:56px;
}

`

export default LayoutStyle